import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import AwarenessScreen from "./Screens/AwarenessScreen";
import TagManager from "react-gtm-module";
import { generateTokenService } from "./Services/Subscription";
import { IgenTokenApiResp } from "./@types/ApiResponse";
import { gtmId } from "./utils/config";

function AwarenessRoute() {
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    handleGenerateToken();
    setTimeout(() => {
      initializeGtm();
    }, 1000);
  }, []);

  const initializeGtm = () => {
    try {
      const tagManagerArgs = {
        gtmId: gtmId,
      };
      TagManager.initialize(tagManagerArgs);
    } catch (error) {}
  };

  // handleGenerateToken
  const handleGenerateToken = async () => {
    try {
      let response: IgenTokenApiResp = await generateTokenService();
      if (response.status === 0) {
        setToken(response.response.token);
      } else {
        console.log("Something went wrong");
      }
    } catch (error) {
      console.log("handleGenerateToken", error);
    }
  };
  return (
    <>
      <Routes>
        <Route path="/awareness" element={<AwarenessScreen token={token} />} />
      </Routes>
    </>
  );
}

export default AwarenessRoute;
