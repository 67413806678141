import {
  IAwarenessFlowResp,
  ICheckOperatorResp,
  ICheckUtmResp,
  IgenTokenApiResp,
  IGetFlowResp,
  ISendOtpResp,
  ISubscribeResp,
} from "../@types/ApiResponse";
import { ApiName } from "../utils/ApiName";
import { fetchApiGet, fetchApiPost } from "../Services/NetworkSrevice";
import { password, username } from "../utils/config";

export const generateTokenService = async () => {
  let requestData = {
    username: username,
    password: password,
  };
  let data: IgenTokenApiResp = await fetchApiPost(
    "",
    ApiName.generateToken,
    requestData
  );
  return data;
};

export const getFlowService = async (
  requestData: {
    utm_source: string | null;
    package_id: string;
  },
  token: string | null
) => {
  let data: IGetFlowResp = await fetchApiPost(
    token,
    ApiName.getFlow,
    requestData
  );
  return data;
};
export const awarenessFlowService = async (
  requestData: {
    utm_source: string | null;
    package_id: string;
  },
  token: string | null
) => {
  let data:IAwarenessFlowResp = await fetchApiPost(
    token,
    ApiName.awarenessFlow,
    requestData
  );
  return data;
};
export const sendOtpService = async (
  requestData: {
    msisdn: string;
  },
  token: string | null
) => {
  let data: ISendOtpResp = await fetchApiPost(
    token,
    ApiName.sendOtp,
    requestData
  );
  return data;
};

export const subscribeUserService = async (
  token: string | null,
  endpoint: string,
  requestData: {
    msisdn: string;
    otp: string;
    package_id: string;
    utm_source: string | null;
  }
) => {
  let data: ISubscribeResp = await fetchApiPost(token, endpoint, requestData);
  return data;
};

export const checkOperatorService = async (
  phoneNumber: string,
  token: string | null
) => {
  let endPoint = ApiName.checkOperator + "?msisdn=" + phoneNumber;
  let data: ICheckOperatorResp = await fetchApiGet(token, endPoint);
  return data;
};

export const checkUtmService = async (token: string | null) => {
  let data: ICheckUtmResp = await fetchApiGet(token, ApiName.utmList);
  return data;
};
