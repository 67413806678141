import React from "react";
import MainImage from "../Assets/imgs/awareness-main-img.webp";
import "../styles/Awareness.css";
import successIcon from "../Assets/imgs/successIcon.png";

type Props = {
  isHidden: boolean;
  handleOverLay: () => void;
};

const Awareness = (props: Props) => {
  return (
    <>
      {props.isHidden === false && (
        <>
          <div
            className="modal-overlay"
            onClick={() => props.handleOverLay()}
          ></div>

          <div className="modal">
            <button
              className="close-modal"
              onClick={() => props.handleOverLay()}
            >
              X
            </button>
            <div className="modal-content">
              <img src={successIcon} alt="icon" />
              <p>You are successfully subscribed to Bima Pakistan.</p>
              <p> Please call (042) 111 119 878 for more details.</p>
            </div>
          </div>
        </>
      )}
      <div className="awareness-page">
        <div className="image">
          <img
            src={MainImage}
            width={340}
            height={598}
            alt="awareness main image"
          />
        </div>
      </div>
    </>
  );
};

export default Awareness;
